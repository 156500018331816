<template>
  <div class="d-block video-item">
    <b-aspect aspect="16:9" class="cover" >
      <img :src="item.img_cover_small" alt="cover" />
      <div class="duration">{{item.duration}}</div>
    </b-aspect>
    <div class="row detail">
      <div class="col-1">
        <img width="30" rounded="circle" src="../../../assets/images/logo/logo.png">
      </div>
      <div class="col-10 ml-2">
        <div class="title" v-if="item.name.length > 55" :title="item.name">{{ item.name.substring(0, 55)+".." }}</div>
        <div class="title" v-else :title="item.name">{{ item.name }}</div>
        <div class="author">{{ 'WYA Yoga' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  name: 'MovieItem',
  methods: {
  }
}
</script>

<style scoped>
  .video-item {
    cursor: pointer;
    display: flex;
  }
  .video-item .cover {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #000;
  }
  .video-item:hover .cover img {
    opacity: .7;
    transform: scale(1.25, 1.25);
  }
  .video-item .cover img {
    position: absolute;
    width: 100%;
    transition: transform ease .25s;
  }
  .video-item .cover .duration {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 3px 6px;
    line-height: 1.2em;
    background-color: rgba(0,0,0, .65);
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
  }
   .video-item .detail {
    margin-top: 15px;
    padding-left: 10px;
    font-size: 14px;
    color: rgba(0,0,0, .45);
  }
  .video-item .detail .title {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .video-item .detail .author{
    font-size: 13px;
    /* line-height: 1em; */
    margin-top: 10px;
  }
  .video-item .detail .date {
    display: inline-block;
    padding: 3px 6px;
    line-height: 1em;
    background-color: #31C2F2;
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
  }
  .video-item .detail .data.hot {
    background-color: #ff6060;
  }

</style>
